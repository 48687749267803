<template>
  <div>
    <h4 class="text-nowrap mr-sm-4 mb-0 header-tablepage">Product analysis</h4>
    <section
      class="shadow p-3 rounded-xl bg-white h-100 d-flex flex-column mt-3"
    >
      <HeaderPanel
        title="Best Seller"
        :filter="filter"
        @sidebar="toggleSideBar(1)"
        :hasExport="true"
        :hasSearch="false"
        @export="exportExcel"
        :hasDropdown="false"
        class="mb-2 mb-lg-3 text-section-color"
      />
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="itemsTable"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(image_url)="data">
              <div class="d-flex justify-content-center">
                <b-img
                  class="box-image-component1-1"
                  :src="
                    data.item.image_url ||
                    '@/assets/img/default-placeholder.png'
                  "
                  fluid
                  @error="handleImageSrc"
                ></b-img>
              </div>
            </template>
            <template v-slot:cell(price)="data">
              {{ data.item.price | numeral("0,.00") }}
            </template>
            <template v-slot:cell(ATV)="data">
              {{ data.item.ATV | numeral("0,.00") }}
            </template>
            <template v-slot:cell(UPT)="data">
              {{ data.item.UPT | numeral("0,.00") }}
            </template>
            <template v-slot:cell(sales)="data">
              {{ data.item.sales | numeral("0,.00") }}
            </template>
            >
            <template v-slot:cell(name)="data">
              <div class="text-left">
                <router-link :to="'/product/detail/' + data.item.product_id">
                  <b-button variant="link" class="text-left name-link pl-0">
                    {{ data.item.name ? data.item.name : "-" }}
                  </b-button>
                </router-link>
                <div>
                  {{ data.item.article_no }}
                  <span class="article-type">{{ data.item.article_type }}</span>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filterPage"
        :rows="rows"
        @pagination="pagination"
      />
    </section>
    <section
      class="shadow p-3 rounded-xl bg-white h-100 d-flex flex-column mt-3"
    >
      <HeaderPanel
        title="Buy Together"
        :filter="filter"
        @sidebar="toggleSideBar(2)"
        :hasSearch="false"
        :hasDropdown="false"
        class="mb-2 mb-lg-3 text-section-color"
      />

      <div class="table-container" @scroll="handleTableScroll" v-if="!isBusy">
        <table id="buy-together-table">
          <tr>
            <th scope="col">Product</th>

            <th
              scope="col"
              v-for="header of headers"
              :key="'cols-' + header"
              class="row-x"
            >
              {{ findProductName(header) }}
            </th>
          </tr>
          <tr v-for="header of headers" :key="'rows-' + header">
            <th scope="row" class="row-y">{{ findProductName(header) }}</th>

            <td v-for="item of headers" :key="'cell-' + item">
              <div v-if="findItems(header)">
                <div
                  v-if="
                    findItems(header)[item] &&
                    item != header &&
                    findItems(header)[item] != 0
                  "
                  :class="['same']"
                  :style="`--background: ${getClass(
                    calPercent(header, findItems(header)[item])
                  )};`"
                >
                  {{ findItems(header)[item] }}
                  ({{ calPercent(header, findItems(header)[item]) }}%)
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filterType == 1 ? filterTable : filter"
      @searchAll="filterType == 1 ? getProductTable() : getBasketList()"
      placeholder="Search Staff ID, Name"
      @clearFilter="clearFilter"
      :hideSearchBar="true"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option v-if="filterType == 1">
        <InputDatePickerFilter
          textFloat="Register Date Form"
          name="registerDateFrom"
          ref="registerDateFrom"
          :value="filterTable.registerDateFrom"
          v-model="filterTable.registerDateFrom"
        />
        <InputDatePickerFilter
          textFloat="Register Date To"
          name="registerDateTo"
          ref="registerDateTo"
          :value="filterTable.registerDateTo"
          v-model="filterTable.registerDateTo"
          :minDatetime="filterTable.registerDateFrom"
        />
        <InputDatePickerFilter
          textFloat="Transaction Date Form"
          name="transactionDateFrom"
          ref="transactionDateFrom"
          :value="filterTable.transactionDateFrom"
          v-model="filterTable.transactionDateFrom"
        />
        <InputDatePickerFilter
          textFloat="Transaction Date To"
          name="transactionDateTo"
          ref="transactionDateTo"
          :value="filterTable.transactionDateTo"
          v-model="filterTable.transactionDateTo"
          :minDatetime="filterTable.transactionDateFrom"
        />

        <InputDropdownCheckbox
          class="mb-2"
          used="channel"
          textFloat="Channel"
          name="channel"
          :valueSelectedAll="filterTable.selectedAll.channel"
          :value="filterTable.channel"
          :options="options.channel"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.channel = val)
          "
          @onDataChange="(val) => (filterTable.channel = val)"
        />
        <InputDropdownCheckbox
          class="mb-2"
          textFloat="Branch"
          name="Branch"
          :valueSelectedAll="filterTable.selectedAll.branch"
          :value="filterTable.branch"
          :options="options.branch"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.branch = val)
          "
          @onDataChange="(val) => (filterTable.branch = val)"
        />
        <InputDropdownCheckbox
          class="mb-2"
          textFloat="Product Category (Level 1)"
          name="Product Category (Level 1)"
          :valueSelectedAll="filterTable.selectedAll.categoryLevel_1"
          :value="filterTable.categoryLevel_1"
          :options="options.categoryLevel_1"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.categoryLevel_1 = val)
          "
          @onDataChange="(val) => (filterTable.categoryLevel_1 = val)"
        />
        <InputDropdownCheckbox
          class="mb-2"
          textFloat="Product Category (Level 2)"
          name="Product Category (Level 2)"
          :valueSelectedAll="filterTable.selectedAll.categoryLevel_2"
          :value="filterTable.categoryLevel_2"
          :options="options.categoryLevel_2"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.categoryLevel_2 = val)
          "
          @onDataChange="(val) => (filterTable.categoryLevel_2 = val)"
        />
        <InputDropdownCheckbox
          class="mb-2"
          textFloat="Product Category (Level 3)"
          name="Product Category (Level 3)"
          :valueSelectedAll="filterTable.selectedAll.categoryLevel_3"
          :value="filterTable.categoryLevel_3"
          :options="options.categoryLevel_3"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.categoryLevel_3 = val)
          "
          @onDataChange="(val) => (filterTable.categoryLevel_3 = val)"
        />
        <InputDropdownCheckbox
          class="mb-2"
          textFloat="Product Category (Level 4)"
          name="Product Category (Level 4)"
          :valueSelectedAll="filterTable.selectedAll.categoryLevel_4"
          :value="filterTable.categoryLevel_4"
          :options="options.categoryLevel_4"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.categoryLevel_4 = val)
          "
          @onDataChange="(val) => (filterTable.categoryLevel_4 = val)"
        />
        <InputDropdownCheckbox
          textFloat="Customer Life Cycle"
          name="Customer Life Cycle"
          class="mb-2"
          :valueSelectedAll="filterTable.selectedAll.customerLifeCycle"
          :value="filterTable.customerLifeCycle"
          :options="options.customerLifeCycle"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.customerLifeCycle = val)
          "
          @onDataChange="(val) => (filterTable.customerLifeCycle = val)"
        />
        <InputDropdownCheckbox
          textFloat="Recency"
          name="recency"
          class="mb-2"
          :valueSelectedAll="filterTable.selectedAll.recency"
          :value="filterTable.recency"
          :options="options.recency"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.recency = val)
          "
          @onDataChange="(val) => (filterTable.recency = val)"
        />
        <InputDropdownCheckbox
          textFloat="Frequency"
          name="frequency"
          class="mb-2"
          :valueSelectedAll="filterTable.selectedAll.frequency"
          :value="filterTable.frequency"
          :options="options.frequency"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.frequency = val)
          "
          @onDataChange="(val) => (filterTable.frequency = val)"
        />
        <InputDropdownCheckbox
          textFloat="Monetary"
          name="monetary"
          :valueSelectedAll="filterTable.selectedAll.monetary"
          :value="filterTable.monetary"
          :options="options.monetary"
          @onDataChangeCheckAll="
            (val) => (filterTable.selectedAll.monetary = val)
          "
          @onDataChange="(val) => (filterTable.monetary = val)"
        />
      </template>
      <template v-slot:filter-option v-else>
        <b-form-checkbox v-model="filter.isProductOnly" size="md" class="mb-2">
          <span class="ml-2 main-label">Product</span>
        </b-form-checkbox>
        <InputSelect
          title="Category x Axis"
          name="Category x Axis"
          v-model="filter.xAxis"
          v-bind:options="axisOptions"
          @input="handleAxisChange('x')"
          valueField="value"
          textField="text"
        />
        <div class="main-label my-2">{{ "X Axis Level" + filter.xAxis }}</div>
        <multiselect
          :close-on-select="false"
          :clear-on-select="false"
          v-model="filter.xAxisId"
          :options="xAxisOptions"
          multiple
          placeholder="Select Category List"
          track-by="id"
          label="name"
        ></multiselect>
        <InputSelect
          title="Category y Axis"
          name="Category y Axis"
          v-model="filter.yAxis"
          v-bind:options="axisOptions"
          @input="handleAxisChange('y')"
          class="mt-2"
          valueField="value"
          textField="text"
        />
        <div class="main-label my-2">{{ "Y Axis Level" + filter.yAxis }}</div>
        <multiselect
          :close-on-select="false"
          :clear-on-select="false"
          v-model="filter.yAxisId"
          :options="yAxisOptions"
          multiple
          placeholder="Select Category List"
          track-by="id"
          label="name"
        ></multiselect>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox";

export default {
  components: {
    Multiselect,
    InputDropdownCheckbox,
  },
  data() {
    return {
      axisOptions: [
        { value: 1, text: "Level 1" },
        { value: 2, text: "Level 2" },
        { value: 3, text: "Level 3" },
        { value: 4, text: "Level 4" },
      ],
      filterPage: {
        page: 1,
        take: 10,
      },
      filter: {
        isProductOnly: true,
        xAxis: 1,
        xAxisId: [],
        yAxis: 2,
        yAxisId: [],
      },
      xAxisOptions: [],
      yAxisOptions: [],
      ah1List: [],
      fields: [
        {
          key: "image_url",
          label: "Product Image",
        },
        {
          key: "name",
          label: "Name / Code / Type",
        },

        {
          key: "price",
          label: "Price",
        },
        {
          key: "ATV",
          label: "ATV",
        },
        {
          key: "UPT",
          label: "UPT",
        },
        {
          key: "sales",
          label: "Sales",
        },
      ],
      items: [],
      itemsTable: [],
      isBusy: false,
      headers: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      productName: [],
      isTrue: true,
      categoryList: {},
      filterTable: {
        registerDateFrom: null,
        registerDateTo: null,
        transactionDateFrom: null,
        transactionDateTo: null,
        channel: [],
        branch: [],
        categoryLevel_1: [],
        categoryLevel_2: [],
        categoryLevel_3: [],
        categoryLevel_4: [],
        customerLifeCycle: [],
        recency: [],
        frequency: [],
        monetary: [],
        selectedAll: {
          categoryLevel_1: false,
          categoryLevel_2: false,
          categoryLevel_3: false,
          categoryLevel_4: false,
          channel: false,
          branch: false,
          customerLifeCycle: false,
          recency: false,
          frequency: false,
          monetary: false,
        },
      },
      filterType: 0,
      options: {
        channel: [],
        branch: [],
        categoryLevel_1: [0],
        categoryLevel_2: [0],
        categoryLevel_3: [0],
        categoryLevel_4: [0],
        customerLifeCycle: [
          { text: "Min", value: 1 },
          { text: "Average Min", value: 2 },
          { text: "Average", value: 3 },
          { text: "Average Max", value: 4 },
          { text: "Max", value: 5 },
        ],
        recency: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        frequency: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
        monetary: [
          { text: "1", value: 1 },
          { text: "2", value: 2 },
          { text: "3", value: 3 },
          { text: "4", value: 4 },
          { text: "5", value: 5 },
        ],
      },
    };
  },
  created() {
    this.getData();
    this.getOption();
  },
  methods: {
    handleChange() {},
    async getProductTable() {
      this.filterTable.registerDateFrom =
        this.filterTable.registerDateFrom || null;
      this.filterTable.registerDateTo = this.filterTable.registerDateTo || null;
      this.filterTable.transactionDateFrom =
        this.filterTable.transactionDateFrom || null;
      this.filterTable.transactionDateTo =
        this.filterTable.transactionDateTo || null;
      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/BestSeller/bestseller-report?page=${this.filterPage.page}&pageSize=${this.filterPage.take}`,
        this.filterTable
      );

      this.itemsTable = res.data.data;
      this.rows = res.data.total;
    },
    async getBasketList() {
      this.isBusy = true;
      this.headers = [];
      let payload = { ...this.filter };
      payload.xAxisId = payload.xAxisId.map(({ id }) => id);
      payload.yAxisId = payload.yAxisId.map(({ id }) => id);

      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/BasketAnalysis/basket-report`,
        payload
      );

      this.productName = res.data.mapping;
      for (const [key, value] of Object.entries(res.data.mapping)) {
        this.headers.push(key);
      }

      this.items = res.data.data;
      this.isBusy = false;
    },

    async getOption() {
      const res = await this.axios(
        `${process.env.VUE_APP_REPORT_API}/BasketAnalysis/product-categories-flat`
      );

      this.categoryList = res.data;
      this.xAxisOptions = res.data[1];
      this.yAxisOptions = res.data[2];
      this.options.categoryLevel_1 = res.data[1].map((el) => {
        return { text: el.name, value: el.id };
      });
      this.options.categoryLevel_2 = res.data[2].map((el) => {
        return { text: el.name, value: el.id };
      });
      this.options.categoryLevel_3 = res.data[3].map((el) => {
        return { text: el.name, value: el.id };
      });
      this.options.categoryLevel_4 = res.data[4].map((el) => {
        return { text: el.name, value: el.id };
      });
      const saleResponse = await this.axios(
        `${process.env.VUE_APP_REPORT_API}/RFMGraph/sales-channel`
      );
      this.$nextTick(() => {
        this.options.channel = saleResponse.data.map((el) => {
          return { text: el.name, value: el.id };
        });
      });

      const result = await this.axios(`/transactioncode/branch`);
      let branchList = [];
      for (const branch of result.data.detail) {
        branchList.push({
          text: `[${branch.plant_id}] ${branch.name} `,
          value: branch.id,
        });
      }

      this.options.branch = branchList;
    },

    async getData() {
      this.getProductTable();
      this.getBasketList();
    },
    handleTableScroll() {},
    pagination(page) {
      this.filterPage.page = page;
      this.getProductTable();
    },
    handleChangeTake() {
      this.getProductTable();
    },
    toggleSideBar(type) {
      this.filterType = type;
      this.$refs.sidebarFilter.show();
    },
    findProductName(id) {
      return this.productName[id];
    },
    findItems(id) {
      return this.items.find((el) => el.id == id);
    },
    calPercent(id, value) {
      let result = 0;
      let object = this.items.find((el) => el.id == id);
      if (object) {
        for (const [key, value] of Object.entries(object)) {
          if (this.headers.includes(key) && key != "id") {
            result += Number(value);
          }
        }
        return Math.round((Number(value) / Number(result)) * 100);
      }
      return 0;
    },
    getClass(val) {
      if (val > 1 && val <= 25) return "#e6cbff";
      else if (val > 25 && val <= 50) return "#d3a4ff";
      else if (val > 50 && val <= 75) return "#b565ff";
      else if (val > 75 && val <= 100) return "#5300a1";
    },
    clearFilter() {
      if (this.filterType == 1) {
        this.filterTable = {
          registerDateFrom: null,
          registerDateTo: null,
          transactionDateFrom: null,
          transactionDateTo: null,
          channel: [],
          branch: [],
          categoryLevel_1: [],
          categoryLevel_2: [],
          categoryLevel_3: [],
          categoryLevel_4: [],
          customerLifeCycle: [],
          recency: [],
          frequency: [],
          monetary: [],
          selectedAll: {
            categoryLevel_1: false,
            categoryLevel_2: false,
            categoryLevel_3: false,
            categoryLevel_4: false,
            channel: false,
            branch: false,
            customerLifeCycle: false,
            recency: false,
            frequency: false,
            monetary: false,
          },
        };
      } else {
        this.filter = {
          isProductOnly: true,
          xAxis: 1,
          xAxisId: [],
          yAxis: 2,
          yAxisId: [],
        };
      }
    },
    selectCateAh1(item, index) {
      this.$emit("searchAH2", item.ah_1_id);
    },
    async getCateProductAh1(search) {
      let body = {
        search: "",
      };
      await this.$store.dispatch("getCategoryProductAh1", body);
      const data = this.$store.state.product.stateCategoryProductAh1;
      if (data.result === 1) {
        return data.detail || [];
      }
      return [];
    },
    async getCategoryAH2() {
      this.isLoadingAh2 = true;
      let body = {
        search: "",
        ah1_id: 0,
      };
      await this.$store.dispatch("getCategoryProductAh2", body);
      const data = this.$store.state.product.stateCategoryProductAh2;
      if (data.result === 1) {
        this.ah2 = data.detail || [];
        this.isLoadingAh2 = false;
      }
    },

    async getCategoryAH3() {
      this.isLoadingAh3 = true;
      let body = {
        search: this.seachKeywordAH3,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
      };
      await this.$store.dispatch("getCategoryProductAh3", body);
      const data = this.$store.state.product.stateCategoryProductAh3;
      if (data.result === 1) {
        this.ah3 = data.detail || [];
        this.isLoadingAh3 = false;
      }
    },

    async getCategoryAH4() {
      this.isLoadingAh4 = true;
      let body = {
        search: this.seachKeywordAH4,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
        ah3_id: this.ah3_id,
      };
      await this.$store.dispatch("getCategoryProductAh4", body);
      const data = this.$store.state.product.stateCategoryProductAh4;
      if (data.result === 1) {
        this.ah4 = data.detail || [];
        this.isLoadingAh4 = false;
      }
    },
    async handleAxisChange(axis) {
      switch (axis) {
        case "x":
          this.xAxisOptions = this.categoryList[this.filter.xAxis];
          break;

        case "y":
          this.yAxisOptions = this.categoryList[this.filter.yAxisId];
          break;
      }
    },
    async exportExcel() {
      const response = await this.axios.post(
        `/dashboard/expoprt/bestseller`,
        this.filterTable,
        {
          responseType: "blob",
        }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `bestseller-report.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scope>
.text-section-color {
  .header-tablepage {
    color: #a2a2b5 !important;
  }
}
#buy-together-table {
  th:not(:last-child) {
    border-right: 1px solid gainsboro;
  }
  th,
  td {
    min-width: 200px;
    text-align: center;
  }

  td {
    background-color: whitesmoke;
    .same {
      color: white;
      background-color: var(--background);
      // padding: ;
      padding: 1.25rem;
    }
    padding: unset !important;
  }
  th {
    background: white;
    position: sticky;
    // box-shadow: inset 0 0 10px 3px #d1d1d173;
    &.row-y {
      left: 0;
    }
    &.row-x {
      top: 0;
    }
  }
  max-height: 450px;
  box-sizing: border-box;
  width: 100%;
}
.table-container {
  overflow: auto;
  height: 700px;
}

.range-1 {
  background-color: #e6cbff;
}
.range-2 {
  background-color: #d3a4ff;
}
.range-3 {
  background-color: #b565ff;
}
.range-4 {
  background-color: #5300a1;
}
</style>
